import * as THREE from "three";
import Delaunator from "delaunator";
import { scale } from "../config/ViewerConfig";
import getIntersection from "./Intersections";
// Types
import { MeshType, MeshColorType } from "../../types/mesh.types";
import { Lut } from "three/examples/jsm/math/Lut";

// 地形のポイントを作成
const createMeshPoints = (array: MeshType[]): THREE.Vector3[] => {
  const points = array.map((item) => {
    const point = new THREE.Vector3(
      item.x / scale,
      item.y / scale,
      item.z / scale
    );
    return point;
  }) as THREE.Vector3[];
  return points;
};

// Recursiveの回数
const recursiveCount = 2;

// 中心点を返す
const getCenterPoint = (points: MeshType[]): THREE.Vector3 => {
  const x = points.reduce((sum, point) => sum + point.x, 0) / points.length;
  const y = points.reduce((sum, point) => sum + point.y, 0) / points.length;
  const z = points.reduce((sum, point) => sum + point.z, 0) / points.length;
  const center = new THREE.Vector3(x, y, z);
  return center;
};

// Recursive
const recursive = (points3d: THREE.Vector3[], meshIndex: number[], recursiveCount: number) => {
  const recursivePoints = [] as THREE.Vector3[];
  for (let i = 0; i < recursiveCount; i++) {
    for (let j = 0; j < meshIndex.length / 3; j++) {
      // faceポイントの中心点を取得
      const facePoints = [
        points3d[meshIndex[j * 3]],
        points3d[meshIndex[j * 3 + 1]],
        points3d[meshIndex[j * 3 + 2]],
      ];
      recursivePoints.push(getCenterPoint(facePoints));
    }
    points3d = points3d.concat(recursivePoints);
    // delaunay
    const indexDelaunay = Delaunator.from(
      points3d.map((v) => {
        return [v.x, v.y];
      })
    );
    // meshIndexを更新
    meshIndex = [] as number[]; // delaunay index => three.js index
    for (let i = 0; i < indexDelaunay.triangles.length; i++) {
      meshIndex.push(indexDelaunay.triangles[i]);
    }
  }
  const geom = new THREE.BufferGeometry().setFromPoints(points3d);
  geom.setIndex(meshIndex);
  geom.computeVertexNormals();

  return { geom, points3d };
};

const createMeshGeometry = (points: THREE.Vector3[]):{geom: THREE.BufferGeometry<THREE.NormalBufferAttributes>, points3d: THREE.Vector3[]} => {
  const points3d = points;

  const geom = new THREE.BufferGeometry().setFromPoints(points);

  // triangulate x, z
  var indexDelaunay = Delaunator.from(
    points3d.map((v) => {
      return [v.x, v.y];
    })
  );

  const meshIndex = []; // delaunay index => three.js index
  for (let i = 0; i < indexDelaunay.triangles.length; i++) {
    meshIndex.push(indexDelaunay.triangles[i]);
  }

  geom.setIndex(meshIndex); // add three.js index to the existing geometry
  geom.computeVertexNormals();

  // recursive
  const newGeom = recursive(points3d, meshIndex, recursiveCount);

  return newGeom;
};

const getDistanceBetweenInitialMesh = (initialPoints: THREE.Vector3[], points: THREE.Vector3[]) => {
  let distance = [];
  if (initialPoints.length > 0) {
    const {geom} = createMeshGeometry(initialPoints);
    distance = points.map((point) => {
      const intersectionPoint = getIntersection(point, geom);
      if (intersectionPoint) {
        const d = point.distanceTo(intersectionPoint);
        // 小さすぎる場合は0にする
        const d_return = d > 0.001 ? d : 0;
        return d_return;
      }
      return 0.0;
    });
  } else {
    distance = points.map((point) => 0.0);
  }
  return distance;
};

const setLut = (
  lut: Lut,
  colorMap: 0 | 1,
  method: 0 | 1 | 2,
  points3d = [] as THREE.Vector3[],
  distance = [] as number[]
) => {
  let max = 0;
  let min = 0;
  let values = [];
  switch (method) {
    case 0:
      values = points3d.map((point) => point.z);
      max = Math.max(...values);
      min = Math.min(...values);
      if (max === min){
        max = max + 1;
        min = min - 1;
      };
      lut.setMax(max);
      lut.setMin(min);
      break;
    case 1:
      values = points3d.map((point) => point.z);
      max = Math.max(...values);
      min = Math.min(...values);
      if (max === min){
        max = max + 1;
        min = min - 1;
      };
      lut.setMax(max);
      lut.setMin(min);
      break;
    case 2:
      max = Math.max(...distance);
      min = Math.min(...distance);
      max>0?lut.setMax(max):lut.setMax(1);
      lut.setMin(min);
      break;
    default:
      break;
  }
  // colorMap
  switch (colorMap) {
    case 0:
      lut.setColorMap("bluttowhite", 512);
      break;
    case 1:
      lut.setColorMap("rainbow", 512);
      break;
    default:
      break;
  }
}

// mesh colorを設定
const setMeshColor = (geom: THREE.BufferGeometry, color: MeshColorType, points3d: THREE.Vector3[], distance: number[]) => {
  const method = color.method;
  const lut = color.lut;
  var max = 0;
  var min = 0;
  var values = [];
  const colors = [];
  // get point.Z
  values = points3d.map((point) => point.z);
  // method
  switch (method) {
    // 高さ
    case 0:
      values.forEach((value) => {
        const color = lut.getColor(value);
        colors.push(color.r);
        colors.push(color.g);
        colors.push(color.b);
      });
      break;
    // 高さ＋傾斜
    case 1:
      if (geom.attributes.normal) {
        const normalArray = geom.attributes.normal.array;
        for (let i = 0; i < normalArray.length / 3; i++) {
          const normal = new THREE.Vector3(
            normalArray[i * 3],
            normalArray[i * 3 + 1],
            normalArray[i * 3 + 2]
          );
          const angle = Math.PI - normal.angleTo(new THREE.Vector3(0, 0, 1));
          const value = values[i];
          const color = calcHsl(lut.getColor(value), angle);
          colors.push(color.r);
          colors.push(color.g);
          colors.push(color.b);
        }
      }
      break;
    // 設計時との差分
    case 2:
      max = Math.max(...distance);
      min = Math.min(...distance);
      // maxも0の時は1にする
        max>0?lut.setMax(max):lut.setMax(1);
      lut.setMin(min);
      distance.forEach((value) => {
        const color = lut.getColor(value);
        colors.push(color.r);
        colors.push(color.g);
        colors.push(color.b);
      });
      break;
    default:
      break;
  }

  geom.setAttribute("color", new THREE.Float32BufferAttribute(colors, 3));

  geom.attributes.color.needsUpdate = true;
  return geom;
};

// 数値をremap
const remap = (value: number, x1: number, y1: number, x2: number, y2: number) =>
  ((value - x1) * (y2 - x2)) / (y1 - x1) + x2;
// HSLの数値を出力
const calcHsl = (color: THREE.Color, angle: number) => {
  const angleRemapS = remap(angle, 0, Math.PI / 4, 0.9, 0.0);
  // const angleRemapS = remap(angle, 0, Math.PI / 4, 0.8, 0.4);
  // const angleRemapL = remap(angle, 0, Math.PI / 4, 0.6, 0.5);
  // const angleRemapL = remap(angle, 0, Math.PI / 4, 0.6, 0.2);
  var hsl = new THREE.Color() as any;
  color.getHSL(hsl);
  // 色相
  const h = hsl.h;
  // 彩度
  const s = angleRemapS > 0.8 ? 0.4 : (1 - angleRemapS) ** 0.5;
  // 輝度
  // const l = sigmoid(angleRemapL, 9.9, -5.8);
  // const l = hsl.l;
  const l = angleRemapS > 0.8 ? 0.6 : hsl.l;
  var newColor = new THREE.Color();
  newColor.setHSL(h, s, l);
  return newColor;
};
// LUTのカラーマップ全体を変換する関数
function lutToHexCodes(lutColormap: THREE.Color[]): string[] {
  return lutColormap.map(color => `#${color.getHexString()}`);
}

export {
  createMeshPoints,
  createMeshGeometry,
  setMeshColor,
  lutToHexCodes,
  setLut,
  getDistanceBetweenInitialMesh,
};
