import { useContext, useState, useEffect } from "react";
// MUI
import { Button, Menu, MenuItem, Divider } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// Components
import PileTablePage from "./PileTablePage";
import UpdatePage from "../UpdatePage";
// Utils
import {
  pileToCsv,
  meshToCsv,
  downloadZip,
  convertDateStringToCSVFilename,
} from "../utils/csvUtils";
// Contexts
import ProjectContext from "../contexts/ProjectContext";
import ObjectContext from "../contexts/ObjectContext";
// Amplify
import { Auth } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";

const ProjectInfoMenu = () => {
  // Contexts
  const { projectProvided } = useContext(ProjectContext);
  const { objectProvided } = useContext(ObjectContext);
  // Auth
  const { user } = useAuthenticator((context) => [context.user]);
  const [isEditableUser, setIsEditableUser] = useState(false);
  //   State
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onExport = () => {
    const pileData = pileToCsv(objectProvided.piles);
    const meshData = meshToCsv(objectProvided.mesh);
    const projectName = projectProvided.project.name;
    const fileName = convertDateStringToCSVFilename(
      projectProvided.selectedPile.name
    );
    downloadZip([meshData, pileData], projectName, fileName);
  };
  const [pileTableOpen, setPileTableOpen] = useState(true);
  const onPileTable = () => {
    setPileTableOpen(!pileTableOpen);
    handleClose();
  };
  //  Update PileSet Page
  const [pileSetPageOpen, setPileSetPageOpen] = useState(false);
  const onAddPileSet = () => {
    setPileSetPageOpen(true);
    handleClose();
  };
  const showPileSetPage = () => {
    if (pileSetPageOpen) {
      return (
        <UpdatePage
          initialStatus="newPileSet"
          open={pileSetPageOpen}
          setOpen={setPileSetPageOpen}
        />
      );
    }
  };
  //  Update Page
  const [meshPageOpen, setmeshPageOpen] = useState(false);
  const onAddNewMesh = () => {
    setmeshPageOpen(true);
    handleClose();
  };
  const showMeshPage = () => {
    if (meshPageOpen) {
      return (
        <UpdatePage
          initialStatus="updateMesh"
          open={meshPageOpen}
          setOpen={setmeshPageOpen}
        />
      );
    }
  };

  useEffect(() => {
    Auth.currentSession().then((user) => {
      const { payload } = user.getIdToken();
      if (payload && payload["cognito:groups"]) {
        setIsEditableUser(
          payload["cognito:groups"].includes(
            "User_" + projectProvided.project.id
          ) || payload["cognito:groups"].includes("Admin")
        );
      }
    });
  }, [user, projectProvided]);
  return (
    <div>
      <Button
        variant="outlined"
        size="small"
        color="inherit"
        onClick={handleMenu}
        endIcon={<KeyboardArrowDownIcon />}
      >
        プロジェクト情報
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={onPileTable}>杭一覧</MenuItem>
        <Divider />
        {isEditableUser && (
          <>
            <MenuItem onClick={onAddNewMesh}>地盤データを更新</MenuItem>
            <MenuItem onClick={onAddPileSet}>新しい杭リストを追加</MenuItem>
            <Divider />
          </>
        )}
        
        <MenuItem onClick={onExport}>エクスポート</MenuItem>
      </Menu>
      <PileTablePage open={pileTableOpen} setOpen={setPileTableOpen} />
      {showPileSetPage()}
      {showMeshPage()}
    </div>
  );
};

export default ProjectInfoMenu;
