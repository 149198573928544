import React, {
  useState,
  useContext,
  useRef,
  Suspense,
  useEffect,
} from "react";
import { Canvas, useFrame, useThree, extend } from "react-three-fiber";
import {
  GizmoViewport,
  GizmoHelper,
  useContextBridge,
  Loader,
} from "@react-three/drei";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { styled } from "@mui/material/styles";
// Contexts
import PilePanelContext from "./contexts/PilePanelContext";
import CameraContext from "./contexts/CameraContext";
import ObjectContext from "./contexts/ObjectContext";
// Objects
import * as THREE from "three";
import Delauney from "./objects/Delauney";
import PileInstance from "./objects/PileInstance";
// Items
import Legend from "./items/Legend";
// Utils
import { lutToHexCodes } from "./utils/meshUtils";
THREE.Object3D.DefaultUp.set(0, 0, 1);

const CanvasContainer = styled("div")({
  width: "100%",
  height: "100%",
  position: "fixed",
  top: 0,
  left: 0,
  boxSizing: "border-box",
  zIndex: -1,
});

const eps = 0.1;
function equals(a, b) {
  return (
    Math.abs(a.x - b.x) < eps &&
    Math.abs(a.y - b.y) < eps &&
    Math.abs(a.z - b.z) < eps
  );
}
extend({ OrbitControls });
const CameraControls = (props) => {
  const {
    camera,
    set,
    gl: { domElement },
  } = useThree();
  const { ortho, cameraStatus, animate, setAnimate, focus, objectProvided } =
    props;
  const controls = useRef();
  const target = useRef();
  useEffect(() => {
    console.log("CameraControls Rendered");
    if (controls.current) {
      let position;
      const box = objectProvided.mesh.box;
      const center = box.getCenter(new THREE.Vector3());
      target.current = center;
      const size = box.getSize(new THREE.Vector3());
      if (cameraStatus === "top") {
        const window_offset_ratio = 0.0006 * Math.max(size.x, size.y) + 0.01;
        controls.current.object = new THREE.OrthographicCamera(
          window.innerWidth * -window_offset_ratio,
          window.innerWidth * window_offset_ratio,
          window.innerHeight * window_offset_ratio,
          window.innerHeight * -window_offset_ratio,
          5,
          2000
        );
        position = new THREE.Vector3(center.x, center.y, 200);
        controls.current.object.up = new THREE.Vector3(0, 1, 0);
        controls.current.object.position.copy(position);
        controls.current.update();
      } else {
        // perspective
        controls.current.object = new THREE.PerspectiveCamera(
          60,
          window.innerWidth / window.innerHeight,
          1,
          2000
        );
        position = new THREE.Vector3(
          box.min.x,
          box.min.y,
          Math.max(size.x, size.y) * 1.2
        );
        controls.current.object.position.copy(position);
        controls.current.object.up = new THREE.Vector3(0, 0, 1);
        controls.current.object.updateProjectionMatrix();
        controls.current.update();
      }
    }
    set({ camera: controls.current.object });
  }, [cameraStatus, set, objectProvided.mesh]);

  // 選択された杭へのズーム
  const vec = new THREE.Vector3();
  useFrame((state) => {
    if (cameraStatus === "top") {
      controls.current.target = new THREE.Vector3(
        controls.current.object.position.x,
        controls.current.object.position.y,
        0
      );
      controls.current.object.up = new THREE.Vector3(0, 1, 0);
    } else {
      target.current = controls.current.target;
    }
    if (animate) {
      var step = cameraStatus === "top" ? 0.05 : 0.05;
      cameraStatus === "top"
        ? vec.set(focus.x, focus.y, focus.z + 2)
        : vec.set(focus.x - 15, focus.y - 15, focus.z + 15);
      controls.current.object.position.lerp(vec, step);

      if (cameraStatus === "top") {
        const newVec = controls.current.object.position.clone();
        controls.current.target = newVec;
        controls.current.object.up = new THREE.Vector3(0, 1, 0);
      } else {
        controls.current.target = focus;
      }
      if (!equals(controls.current.object.position, vec)) {
        return;
      } else {
        setAnimate(false);
      }
    }
    controls.current.update();
  });

  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      enableRotate={!ortho}
      makeDefault
      target={target.current}
    />
  );
};

const Viewer = (cameraState) => {
  // Context
  const { cameraProvided } = useContext(CameraContext);
  const { objectProvided } = useContext(ObjectContext);

  const ContextBridge = useContextBridge(PilePanelContext, ObjectContext);
  console.log("Viewer Rendered");

  const ortho = cameraProvided === "top";
  //
  const [focus, setFocus] = useState();
  const [animate, setAnimate] = useState(false);
  //

  const lut = objectProvided.meshColor.lut;

  return (
    <>
      <CanvasContainer>
        <Canvas
          shadows={{ type: "BasicShadowMap" }}
          style={{ background: "#5c5c5c" }}
        >
          <CameraControls
            ortho={ortho}
            cameraStatus={cameraProvided}
            focus={focus}
            setFocus={setFocus}
            animate={animate}
            setAnimate={setAnimate}
            objectProvided={objectProvided}
          />
          <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
            <GizmoViewport />
          </GizmoHelper>
          <ambientLight intensity={0.4} color={"white"} />
          <directionalLight position={[-10, -5, 10]} intensity={0.2} />
          <ContextBridge>
            <Suspense fallback={null}>
              <Delauney />
              <PileInstance
                focus={focus}
                setFocus={setFocus}
                animate={animate}
                setAnimate={setAnimate}
              />
            </Suspense>
          </ContextBridge>
        </Canvas>
        {objectProvided.mesh.points.length > 0 && (
          <Legend
            min={lut.minV}
            max={lut.maxV}
            colorScale={lutToHexCodes(lut.lut)}
          />
        )}
      </CanvasContainer>
      <Loader />
    </>
  );
};

export default Viewer;
