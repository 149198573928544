import { FormControl, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { useContext } from "react";
// Contexts
import ProjectContext from "../contexts/ProjectContext";
import ObjectContext from "../contexts/ObjectContext";
// Utilities
import { fileUploadErrorAlert } from "../utils/alertUtils";
// Ampify
import { API } from "aws-amplify";
import { updateProjectSettings } from "../../graphql/mutations";

const MeshOption = () => {
  // Contexts
  const { projectProvided } = useContext(ProjectContext);
  const { objectProvided, setObjectProvided } = useContext(ObjectContext);
  // Change Pile File
  const handleChange = async (event:SelectChangeEvent<number>) => {
    const project = projectProvided.project;
    const newMeshColor = { ...objectProvided.meshColor };
    newMeshColor.method = Number(event.target.value) as 0 | 1 | 2;
    setObjectProvided({ type: "setMeshColor", payload: newMeshColor });
    try {
      // update project settings
      await API.graphql({
        query: updateProjectSettings,
        variables: {
          projectId: project.id,
          projectSettingsInput: {
            meshIndexParam: newMeshColor.method,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    } catch (e) {
      fileUploadErrorAlert();
    }
  };
  return (
    <FormControl fullWidth>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={objectProvided.meshColor.method}
        size="small"
        onChange={handleChange}
      >
        <MenuItem value={1}>高さ＋傾斜</MenuItem>
        <MenuItem value={0}>高さ</MenuItem>
        <MenuItem value={2}>設計時との差分</MenuItem>
      </Select>
    </FormControl>
  );
};

export default MeshOption;
