import * as THREE from "three";
import { Lut } from "three/examples/jsm/math/Lut";
import { Color } from "three";
import { MeshColorType, MeshGeometryType } from "../../types/mesh.types";

const lut = new Lut();

lut.addColorMap("bluttowhite", [
  //   [0.0, new Color("rgb(57, 115, 125)")],
  [0.0, new Color("rgb(35, 70, 77)").getHex()],
  //   [0.4, new Color("rgb(11, 61, 133)")],
  [0.4, new Color("rgb(3, 20, 64)").getHex()],
  [0.7, new Color("rgb(67, 128, 240)").getHex()],
  [0.9, new Color("rgb(143, 206, 255)").getHex()],
  [1.0, new Color("rgb(255, 255, 255)").getHex()],
]);

lut.setMax(1);
lut.setMin(0);

const initialMeshColor: MeshColorType = {
  // カラーマップ
  colorMap: 1,
  // 計算方法
  method: 1,
  lut: lut,
};

const initialMeshGeometry: MeshGeometryType = {
  geometry: new THREE.BufferGeometry(),
  initialPoints: [],
  points: [],
  distance: [],
  box: new THREE.Box3(),
};

export { lut, initialMeshColor, initialMeshGeometry };
