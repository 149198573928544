import { FormControl, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { useContext } from "react";
// Contexts
import ProjectContext from "../contexts/ProjectContext";
import ObjectContext from "../contexts/ObjectContext";
// Utilities
import { fileUploadErrorAlert } from "../utils/alertUtils";
// Ampify
import { API } from "aws-amplify";
import { updateProjectSettings } from "../../graphql/mutations";

const MeshColorOption = () => {
  // Contexts
  const { projectProvided } = useContext(ProjectContext);
  const { objectProvided, setObjectProvided } = useContext(ObjectContext);
  // Change Pile File
  const handleChange = async (event: SelectChangeEvent<number>) => {
    const newMeshColor = { ...objectProvided.meshColor };
    const project = projectProvided.project;
    newMeshColor.colorMap = Number(event.target.value) as 0 | 1;
    setObjectProvided({ type: "setMeshColor", payload: newMeshColor });
    try {
      // update project settings
      await API.graphql({
        query: updateProjectSettings,
        variables: {
          projectId: project.id,
          projectSettingsInput:{
            meshColor: newMeshColor.colorMap
          }
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    } catch (e) {
      fileUploadErrorAlert();
    }
  };
  return (
    <FormControl fullWidth>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={objectProvided.meshColor.colorMap}
        size="small"
        onChange={handleChange}
      >
        <MenuItem value={1}>多色</MenuItem>
        <MenuItem value={0}>青色</MenuItem>
      </Select>
    </FormControl>
  );
};

export default MeshColorOption;
