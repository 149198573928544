import { useContext } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  ThemeProvider,
  Button,
  LinearProgress,
} from "@mui/material";
import { darkTheme } from "./darkTheme";
// Components
import TopViewButton from "./items/TopViewButton";
import PileTable from "./items/ProjectInfoMenu";
import UserMenu from "./items/UserMenu";
// Contexts
import ProgressContext from "./contexts/ProgressContext";
import ProjectContext from "./contexts/ProjectContext";
// Amplify
import { API } from "aws-amplify";
import { getTutorial } from "../graphql/queries";

const CustomTypography = styled(Typography)({
  // flexGrow: 1,
});

export default function ButtonAppBar() {
  const { route} = useAuthenticator((context) => [
    context.user,
    context.route,
    context.signOut,
  ]);
  // Contexts
  const { projectProvided } = useContext(ProjectContext);
  const { progressProvided } = useContext(ProgressContext);

  const onTutorialButtonClick = async () => {
    const data = await API.graphql({
      query: getTutorial,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    window.open(data.data.getTutorial, "_blank");
  };

  const inProgress = () => {
    if (progressProvided) {
      return <LinearProgress />;
    }
  };

  return (
    <div style={{ flexGrow: "1" }}>
      <ThemeProvider theme={darkTheme}>
        <AppBar position="fixed">
          <Toolbar>
            <Box
              component="img"
              sx={{
                height: 40,
                width: 40,
              }}
              alt="ANAGO_logo"
              src="/icon.png"
            />
            <CustomTypography variant="h6">
              <Box
                textAlign="left"
                sx={{ mx: 2 }}
                fontSize={16}
                onClick={() => window.location.reload()}
              >
                ANAGO Mobile
              </Box>
            </CustomTypography>
            <Button
              sx={{
                color: "white",
                display: "block",
                float: "left",
                fontSize: 12,
              }}
              target="_blank"
              onClick={onTutorialButtonClick}
            >
              使い方
            </Button>
            <div style={{ flexGrow: 1 }}></div>
            <Box sx={{ mx: 1, fontSize: 12 }}>
              <TopViewButton />
            </Box>
            {projectProvided.project && (
              <Box sx={{ mx: 1, fontSize: 12 }}>
                <PileTable />
              </Box>
            )}
            <Box sx={{ mx: 1, fontSize: 10 }}>
              {route !== "authenticated" ? "" : <UserMenu />}
            </Box>
          </Toolbar>
          {inProgress()}
        </AppBar>
      </ThemeProvider>
    </div>
  );
}
